html {
  -moz-osx-font-smoothing: grayscale !important; /*(For Firefox)*/
  -webkit-font-smoothing: antialiased !important; /*(For Chrome and Safari)*/
}

body {
  font-family: "Montserrat", sans-serif;
}

@tailwind base;
@tailwind components;

input:focus,
button:focus {
  @apply outline-none;
}

@tailwind utilities;

.container {
  max-width: 1280px;
}

.w-fit {
  width: fit-content;
}

.gradient-to-bottom-black {
  background-image: linear-gradient(180deg, #151517 0%, rgba(0, 0, 0, 0) 100%);
}

.gradient-to-top-black {
  background-image: linear-gradient(0deg, #151517 0%, rgba(0, 0, 0, 0) 100%);
}

.fire-gradient {
  background-image: linear-gradient(45deg, #ff9a44 0%, #ff531c 100%);
}

.percentage-gradient {
  background: linear-gradient(270deg, #0ca8ff 0%, #1873ff 100%);
}

.purple-gradient {
  background-image: linear-gradient(93.12deg, #8755e6 24.89%, #b15df2 100%);
}

.purple-gradient-reverse {
  background-image: linear-gradient(24.89%, #8755e6 93.12deg, #b15df2 100%);
}

.text-purple-gradient {
  background: linear-gradient(93.12deg, #8755e6 24.89%, #b15df2 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-rainbow-gradient {
  display: inline-block;
  color: #e92e95;
}

@supports ((background-clip: text) or (-webkit-background-clip: text)) and
  ((text-fill-color: transparent) or (-webkit-text-fill-color: transparent)) {
  .text-rainbow-gradient {
    background-image: linear-gradient(
      75.41deg,
      #13d97a 10.33%,
      #26ade6 37.6%,
      #fb38ff 62.4%,
      #ff971d 89.67%
    );
    background-size: cover;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent;
  }
}

div.rec.rec-slider div div:focus,
div.rec.rec-slider div:focus,
div.rec.rec-item-wrapper:focus {
  outline: 0;
}

div.rec.rec-slider-container {
  min-width: 119% !important;
}

div.rec.rec-item-wrapper {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.light-gradient {
  background-image: linear-gradient(270deg, #f9f9f9 7.06%, #fefefe 96.82%);
}

.gradient-counter {
  background: linear-gradient(104.27deg, #fdfbfb 3.26%, #fefefe 100%);
}

.shadow-counter {
  box-shadow: inset 0px 2px 4px rgba(192, 192, 192, 0.25);
}

.bar1,
.bar3 {
  width: 20px;
  height: 2px;
  margin: 3px auto;
  transition: 0.4s;
}

.invis {
  width: 20px;
  height: 2px;
  margin: 3px auto;
}

.change .bar1 {
  -webkit-transform: rotate(-45deg) translate(-3px, 4px);
  transform: rotate(-45deg) translate(-3px, 4px);
}

.bg-gradient-graphite {
  background: linear-gradient(135deg, #201f24 0%, #060606 100%);
}

.bg-gradient-colorfull {
  background: radial-gradient(
      19.49% 58.52% at 50% 0%,
      rgba(60, 249, 90, 0.3) 0%,
      rgba(1, 231, 231, 0) 100%
    ),
    radial-gradient(
      50% 50% at 100% 100%,
      #6120eb 10.27%,
      rgba(159, 115, 255, 0) 100%
    ),
    radial-gradient(
      77.86% 77.86% at 100% 0%,
      #00a7ff 0%,
      rgba(0, 166, 254, 0.17) 100%
    ),
    radial-gradient(50% 50% at 0% 0%, #6cffa7 0%, rgba(6, 254, 209, 0.18) 100%),
    radial-gradient(
      70.24% 70.24% at 0% 100%,
      #e81d72 0%,
      rgba(255, 0, 0, 0) 100%
    );
  background-blend-mode: overlay, normal, normal, color-dodge, normal;
  mix-blend-mode: normal;
}

.filter-blur-48px {
  filter: blur(48px);
}

.backdrop-blur-8px {
  backdrop-filter: blur(8px);
}

.change .bar3 {
  -webkit-transform: rotate(45deg) translate(-3px, -4px);
  transform: rotate(45deg) translate(-3px, -4px);
}

.version-gradient {
  background: linear-gradient(133.44deg, #f4faff 6.7%, #dcdffc 96.14%);
}

strong {
  /* color: #0065ff; */
  font-weight: 600;
}

em {
  background: -webkit-linear-gradient(180deg, #d886ff 2.5%, #2c9aff 96.79%);
  -webkit-background-clip: text;
  font-style: normal;
  -webkit-text-fill-color: transparent;
  font-weight: normal;
}

.skeleton-box {
  background-color: #e2e8f0;

  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0,
    rgba(255, 255, 255, 0.2) 20%,
    rgba(255, 255, 255, 0.5) 60%,
    rgba(255, 255, 255, 0)
  );
  animation: shimmer 3s infinite;
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

.markdown h1,
.markdown h2,
.markdown h3,
.markdown h4,
.markdown h5,
.markdown h6 {
  font-family: "Clash Display";
  font-weight: 600;
  color: inherit !important;
  margin-top: 10px;
  margin-bottom: 5px;
}

.markdown h1 {
  font-size: 24px;
}

.markdown h2 {
  font-size: 22px;
}

.markdown h3 {
  font-size: 20px;
}

.markdown h4 {
  font-size: 18px;
}

.markdown strong {
  font-family: "Montserrat";
  color: black !important;
}

.markdown p strong {
  font-family: "Montserrat";
  color: black !important;
}

.markdown h2 strong {
  color: black !important;
}

.markdown a {
  color: #0065ff;
}

.markdown th {
  background-color: #f5f5f5;
  padding: 20px 0px;
}

.markdown td {
  padding: 10px 20px;
}

.markdown tbody tr:nth-child(even) {
  background-color: #f5f5f5;
  padding-left: 30px;
}

.markdown th:first-child,
td:first-child {
  padding-left: 30px;
}

.markdown th:last-child,
td:last-child {
  padding-right: 30px;
}

ol {
  list-style-type: decimal;
}

ol > li {
  margin-bottom: 15px;
}

.overflow-x-scroll,
.overflow-y-scroll {
  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important;
}

.overflow-x-scroll::-webkit-scrollbar,
.overflow-y-scroll::-webkit-scrollbar {
  display: none !important;
}

.hide-scrollbar,
.hide-scrollbar::-webkit-scrollbar {
  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important;
}

* {
  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important;
}

*::-webkit-scrollbar {
  display: none;
}

textarea {
  -ms-overflow-style: -ms-autohiding-scrollbar !important; /* IE and Edge */
  scrollbar-width: 2px !important;
}
textarea::-webkit-scrollbar {
  display: block;
}

.tooltip-arrow:after {
  top: 10px;
  right: -19px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent transparent white;
}

.tooltip-arrow:before {
  top: 10px;
  right: -21px;
  bottom: 100%;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-left-color: #eeeeee;
  border-left-color: #eeeeee;
}

.tooltip-arrow:after,
.tooltip-arrow:before {
  content: "";
  display: block;
  position: absolute;
}

.numer-input::-webkit-outer-spin-button,
.number-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.number-input[type="number"] {
  -moz-appearance: textfield;
}

.circle-label {
  transform: translateY(0.35em);
}

.circle-percentage {
  font-size: 0.4em;
  line-height: 1;
  text-anchor: middle;
  font-family: "Inter Medium";
  transform: translateY(-0.44em);
}

.reverse-animation {
  animation-direction: reverse;
}
/* clears the 'X' from Internet Explorer */
input[type="search"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

input[type="text"],
input[type="password"],
input[type="email"],
input[type="search"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

/* Number Arrows */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.table-item-shadow {
  box-shadow: inset 0px 2px 6px rgba(0, 0, 0, 0.04);
}

.ScrollbarsCustom-TrackX {
  background: #f5f5f5 !important;
  left: 0 !important;
  width: 100% !important;
  height: 4px !important;
  border-radius: 26px !important;
}

.ScrollbarsCustom-TrackY {
  background: #f5f5f5 !important;
  top: 0 !important;
  height: 100% !important;
  width: 4px !important;
  border-radius: 26px !important;
}

.dark .ScrollbarsCustom-TrackX,
.ScrollbarsCustom-TrackY {
  background: #232323 !important;
}

.ScrollbarsCustom-ThumbX,
.ScrollbarsCustom-ThumbY {
  background: #d4d4d4 !important;
  border-radius: 26px !important;
}

.dark .ScrollbarsCustom-ThumbX,
.ScrollbarsCustom-ThumbY {
  background: #999999 !important;
  border-radius: 26px !important;
}

.tag-text-shadow {
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
}

.gradient-white-top {
  background-image: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    #ffffff 100%
  );
}

.SubscriptionTypeCustom {
  box-shadow: 0px 0px 1px 0px #0000000a;
  box-shadow: 0px 0px 2px 0px #0000000f;
  box-shadow: 0px 4px 8px 0px #0000000a;
}
.SubscriptionTypeCustom:hover {
  box-shadow: 82px 147px 67px rgba(0, 0, 0, 0.01),
    5px 9px 23px rgba(0, 0, 0, 0.08);
}

.EditSubButtonCustom {
  opacity: 0;
}

.SubscriptionTypeCustom:hover .EditSubButtonCustom {
  opacity: 1;
}

@layer components {
  .landing img {
    image-rendering: -webkit-optimize-contrast;
  }

  .landing-container {
    @apply mx-auto w-full max-w-[1280px] px-4;
  }

  .img-wrapper {
    @apply relative min-w-fit;
  }

  .img-wrapper > span {
    @apply align-middle;
  }

  .fill-icon > path,
  .fill-icon > circle {
    fill: currentColor;
  }

  .arrow-button:hover div:nth-child(1) {
    @apply bg-white;
  }

  .arrow-button:hover div:nth-child(2) {
    @apply border-white;
  }

  .rainbow-text-1 {
    @apply bg-gradient-to-r from-[#DA2801] via-[#D00694] to-[#0CCDE4] bg-clip-text text-transparent;
  }

  .rainbow-text-2 {
    @apply bg-gradient-to-r from-[#14D97B] via-[#CE51FA] to-[#FF912D] bg-clip-text text-transparent;
  }

  .markdown-default * {
    @apply text-sm;
  }

  .markdown-default > * {
    @apply mb-4 last:mb-0;
  }

  .markdown-default ul {
    @apply list-disc;
  }

  .markdown-default ul li {
    @apply ml-6 mb-2 last:mb-0;
  }
}

.emoji-mart-chat {
  transform: translateY(calc(-100% - 32px));
}

.better-grid {
  padding: 0 calc(50% - 640px);
  display: grid;
  grid-template-rows: repeat(2, minmax(275px, 1fr));
  grid-template-columns: repeat(9, minmax(275px, 1fr));
  grid-template-areas:
    "b1 b1 b2 b2 b3 b3 b3 b8 b8"
    "b1 b1 b4 b5 b5 b6 b7 b8 b8";
}

.start-grid {
  display: grid;
  grid-gap: 28px;
  grid-template-columns: repeat(15, 1fr);
  grid-template-areas:
    "s1 s1 s1 s1 s1 s2 s2 s2 s2 s2 s3 s3 s3 s3 s3"
    "s1 s1 s1 s1 s1 s2 s2 s2 s2 s2 s3 s3 s3 s3 s3"
    "s4 s4 s4 s4 s4 s4 s5 s5 s5 s5 s5 s5 s6 s6 s6"
    "s4 s4 s4 s4 s4 s4 s7 s7 s7 s8 s8 s8 s8 s8 s8";
}

@media screen and (max-width: 768px) {
  .better-grid {
    grid-template-rows: repeat(2, minmax(200px, 1fr));
    grid-template-columns: repeat(9, minmax(200px, 1fr));
  }

  .start-grid {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}

@media screen and (max-width: 1280px) {
  .better-grid {
    padding: 0 16px;
  }
}

.better-grid::-webkit-scrollbar {
  display: none;
}

.better-grid-block-1 {
  grid-area: b1;
}

.better-grid-block-2 {
  grid-area: b2;
}

.better-grid-block-3 {
  grid-area: b3;
}

.better-grid-block-4 {
  grid-area: b4;
}

.better-grid-block-5 {
  grid-area: b5;
}

.better-grid-block-6 {
  grid-area: b6;
}

.better-grid-block-7 {
  grid-area: b7;
}

.better-grid-block-8 {
  grid-area: b8;
}

.start-grid-block-1 {
  grid-area: s1;
}

.start-grid-block-2 {
  grid-area: s2;
}

.start-grid-block-3 {
  grid-area: s3;
}

.start-grid-block-4 {
  grid-area: s4;
}

.start-grid-block-5 {
  grid-area: s5;
}

.start-grid-block-6 {
  grid-area: s6;
}

.start-grid-block-7 {
  grid-area: s7;
}

.start-grid-block-8 {
  grid-area: s8;
}

.shadow-custom {
  box-shadow: 0px 0px 2px 0px #0000001a;
  box-shadow: 0px 8px 30px 0px #0000001a;
}

.hover\:shadow-custom:hover {
  box-shadow: 0px 0px 2px 0px #0000001a;
  box-shadow: 0px 8px 30px 0px #0000001a;
}

.CustomButton {
  position: relative;
  overflow: hidden;
  background: #fd3aa6 url("/assets/images/button-tail.svg") no-repeat scroll
    101% 106%;
}

.CustomButton:hover {
  background: #1d1d1d url("/assets/images/button-tail-reversed.svg") no-repeat
    scroll 101% 106%;
}

.stories-container > div > div:nth-child(1) {
  display: flex !important;
  width: 95% !important;
  padding: 8px 0 !important;
}

.stories-container > div > div:nth-child(1) > div {
  border-radius: 10px !important;
  overflow: hidden !important;
  background: rgba(255, 255, 255, 0.5) !important;
  height: 3px !important;
}

.stories-container > div > div:nth-child(2) div {
  width: 100%;
  height: 100%;
}

.stories-container > div > div:nth-child(2) div > img {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
}

.stories-container.stories-ambassador > div > div:nth-child(1) {
  bottom: 6px;
  left: 16px;
  max-width: 40% !important;
}

@media screen and (max-width: 768px) {
  .stories-container.stories-ambassador > div > div:nth-child(1) {
    right: 16px;
    max-width: 60% !important;
    margin: 0 auto !important;
  }
}

.stories-container.stories-ambassador > div > div:nth-child(1) > div {
  height: 5px !important;
}

.markdown > ol {
  padding-left: 1.5em;
}

.markdown > ul > li::before {
  content: "";
  display: inline-block;
  width: 0.3em;
  height: 0.27em;
  background-color: black;
  margin-right: 0.75em;
  margin-left: 0.5em;
  line-height: 1em;
  margin-bottom: 0.25em;
  border-radius: 4em;
}
