/* Clash Display */

@font-face {
  font-family: "Clash Display";
  font-weight: 200;
  font-display: swap;
  src: url("/fonts/ClashDisplay/ClashDisplay-Extralight.woff2") format("woff2"),
    url("/fonts/ClashDisplay/ClashDisplay-Extralight.woff") format("woff"),
    url("/fonts/ClashDisplay/ClashDisplay-Extralight.ttf") format("truetype");
}

@font-face {
  font-family: "Clash Display";
  font-weight: 300;
  font-display: swap;
  src: url("/fonts/ClashDisplay/ClashDisplay-Light.woff2") format("woff2"),
    url("/fonts/ClashDisplay/ClashDisplay-Light.woff") format("woff"),
    url("/fonts/ClashDisplay/ClashDisplay-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Clash Display";
  font-weight: 400;
  font-display: swap;
  src: url("/fonts/ClashDisplay/ClashDisplay-Regular.woff2") format("woff2"),
    url("/fonts/ClashDisplay/ClashDisplay-Regular.woff") format("woff"),
    url("/fonts/ClashDisplay/ClashDisplay-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Clash Display";
  font-weight: 500;
  font-display: swap;
  src: url("/fonts/ClashDisplay/ClashDisplay-Medium.woff2") format("woff2"),
    url("/fonts/ClashDisplay/ClashDisplay-Medium.woff") format("woff"),
    url("/fonts/ClashDisplay/ClashDisplay-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Clash Display";
  font-weight: 600;
  font-display: swap;
  src: url("/fonts/ClashDisplay/ClashDisplay-Semibold.woff2") format("woff2"),
    url("/fonts/ClashDisplay/ClashDisplay-Semibold.woff") format("woff"),
    url("/fonts/ClashDisplay/ClashDisplay-Semibold.ttf") format("truetype");
}

@font-face {
  font-family: "Clash Display";
  font-weight: 700;
  font-display: swap;
  src: url("/fonts/ClashDisplay/ClashDisplay-Bold.woff2") format("woff2"),
    url("/fonts/ClashDisplay/ClashDisplay-Bold.woff") format("woff"),
    url("/fonts/ClashDisplay/ClashDisplay-Bold.ttf") format("truetype");
}

/* Montserrat */

@font-face {
  font-family: "Montserrat";
  font-weight: 200;
  font-display: swap;
  src: url("/fonts/Montserrat/Montserrat-ExtraLight.woff2") format("woff2"),
    url("/fonts/Montserrat/Montserrat-ExtraLight.woff") format("woff"),
    url("/fonts/Montserrat/Montserrat-ExtraLight.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat";
  font-weight: 300;
  font-display: swap;
  src: url("/fonts/Montserrat/Montserrat-Light.woff2") format("woff2"),
    url("/fonts/Montserrat/Montserrat-Light.woff") format("woff"),
    url("/fonts/Montserrat/Montserrat-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat";
  font-weight: 400;
  font-display: swap;
  src: url("/fonts/Montserrat/Montserrat-Regular.woff2") format("woff2"),
    url("/fonts/Montserrat/Montserrat-Regular.woff") format("woff"),
    url("/fonts/Montserrat/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat";
  font-weight: 500;
  font-display: swap;
  src: url("/fonts/Montserrat/Montserrat-Medium.woff2") format("woff2"),
    url("/fonts/Montserrat/Montserrat-Medium.woff") format("woff"),
    url("/fonts/Montserrat/Montserrat-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat";
  font-weight: 600;
  font-display: swap;
  src: url("/fonts/Montserrat/Montserrat-SemiBold.woff2") format("woff2"),
    url("/fonts/Montserrat/Montserrat-SemiBold.woff") format("woff"),
    url("/fonts/Montserrat/Montserrat-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat";
  font-weight: 700;
  font-display: swap;
  src: url("/fonts/Montserrat/Montserrat-Bold.woff2") format("woff2"),
    url("/fonts/Montserrat/Montserrat-Bold.woff") format("woff"),
    url("/fonts/Montserrat/Montserrat-Bold.ttf") format("truetype");
}

/* Jost */

@font-face {
  font-family: "Jost";
  font-weight: 400;
  font-display: swap;
  src: url("/fonts/Jost/Jost-Regular.woff2") format("woff2"),
    url("/fonts/Jost/Jost-Regular.woff") format("woff"),
    url("/fonts/Jost/Jost-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Jost";
  font-weight: 500;
  font-display: swap;
  src: url("/fonts/Jost/Jost-Medium.woff2") format("woff2"),
    url("/fonts/Jost/Jost-Medium.woff") format("woff"),
    url("/fonts/Jost/Jost-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Jost";
  font-weight: 600;
  font-display: swap;
  src: url("/fonts/Jost/Jost-SemiBold.woff2") format("woff2"),
    url("/fonts/Jost/Jost-SemiBold.woff") format("woff"),
    url("/fonts/Jost/Jost-SemiBold.ttf") format("truetype");
}

/* Gravity Points */

@font-face {
  font-family: "Gravity Points";
  font-weight: 400;
  font-display: swap;
  src: url("/fonts/GravityPoints/GravityPoints-Regular.woff2") format("woff2"),
    url("/fonts/GravityPoints/GravityPoints-Regular.woff") format("woff"),
    url("/fonts/GravityPoints/GravityPoints-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Gravity Points Width1";
  font-weight: 400;
  font-display: swap;
  src: url("/fonts/GravityPoints/GravityPointsWidth1-Regular.woff2")
      format("woff2"),
    url("/fonts/GravityPoints/GravityPointsWidth1-Regular.woff") format("woff"),
    url("/fonts/GravityPoints/GravityPointsWidth1-Regular.ttf")
      format("truetype");
}
