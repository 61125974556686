.emoji-mart-search input {
  height: 46px;
  width: 100%;
  padding: 12px 16px;
  border-radius: 8px;
}

.emoji-mart-search-icon {
  width: 16px;
  height: 16px;
  right: 16px !important;
  top: 14px !important;
}

.emoji-mart .emoji-mart-emoji {
  padding: 7.5px;
}

.emoji-mart-bar:first-child {
  display: none;
}

.emoji-mart-bar:last-child {
  display: none;
}

.emoji-mart-anchors {
  display: none;
}

.emoji-mart-preview {
  display: none;
}

.emoji-mart-scroll {
  padding: 0;
}

.emoji-mart-search {
  margin: 0;
  padding: 0;
}

.emoji-mart-category .emoji-mart-emoji span {
  cursor: pointer;
}

.emoji-mart-category-label span {
  text-align: left;
}

.emoji-mart {
  padding: 20px;
}

.emoji-mart-category-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
